<template>
<div class="obj-list wrapper"  > 

        <div v-if="role" class="header-container mb-8">
            <router-link  to="/building-list/newcadaster" tag="button"   class="header-item" active-class="header-item-active"  >
                <div class="header-radio-label"></div>
               {{ $t('change_cadaster_buildings') }}
            </router-link>
            <router-link to="/building-list/exg" tag="button"  v-if="role != 'ministry' &&  role!=='ministry_admin'"  class="header-item"  active-class="header-item-active" >
                <div class="header-radio-label"></div>
                {{$t('existing_buildings_structures')}}
            </router-link>
            <router-link  to="/building-list/new" tag="button"  class="header-item" active-class="header-item-active"  >
                <div class="header-radio-label"></div>
                {{$t('new_buildings_structures')}}
            </router-link>
        </div>
    <AllBuilding  />

</div>
</template>

<script>
import AllBuilding from  '@/components/AllBuilding.vue'
 export default {
     components:{AllBuilding},
     data() {
         return {

         };
     },

     computed: {
         role() {
            return this.$store.state.role;
         },
     },
     mounted(){

     }


 };
</script>
<style lang="scss">
.ctatic-header {
    padding-bottom: 30px;
    padding-top: 20px;

} 
</style>
